$hellgrau: #b3b3b3;
$dunkelgrau: #898989;
$font-headline: 'Oswald', sans-serif;
$font-headline-weight: 400;
$font-content: 'Open Sans', sans-serif;
$font-content-weight:400;
// Quelle Font-Kombination: http://t3n.de/news/15-google-fonts-kombinationen-745037/
// Notiz zum Aufbau: Mobile First Ansatz

// Border Box
html {
  box-sizing: border-box;
  font-size:16px;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  font-family: $font-content;
  font-weight:$font-content-weight;
  font-size:14px;
  line-height:170%;
}

h1 {
  font-family: $font-headline;
  color: $hellgrau;
  text-transform: uppercase;
  font-weight:$font-content-weight;
  font-size:1.4rem;
  line-height: 2rem;
}
h2 {
  font-family: $font-content;
  color: $dunkelgrau;
  //text-transform: uppercase;
  font-weight:normal;
  font-size:14px;
  line-height: 1.5rem;
}
.wrapper {
  max-width:1200px;
  width:100%;
  margin:0 auto;
  padding:1rem;
}
header.nav1 {
  margin-top:0px;
  font-family: $font-headline;
  text-transform: uppercase;
  font-weight: $font-headline-weight;
  font-size:1.6rem;
  line-height:2rem;
}
nav {
  width:100%;
  margin-top:20px;
}
main {
  display:block; // IE<11
  >header {
    padding-top:1rem;
    padding-bottom:1rem;
  }
}
main h1 {
  margin-top:20px;
}
a {
  color:black;
  text-decoration:none;
  &.hellgrau {
    color: $hellgrau;
  }
  &.dunkelgrau {
    color: $dunkelgrau;
  }
}


.galerie {
  overflow-x: hidden;
}
.galerie img {
  height:60px;
  float:left;
  margin-right:10px;
  margin-bottom:10px;
}
.clearfix {
  clear:both;
}

.felixkuhn {
  margin-top:4rem;
  .adress-identifier {
    display:inline-block;
    width:20px;
  }

  .article {
    max-width:100%;
  }
  .adresse, .cv {
    width:100%;
  }
  .adresse {

  }
  .portrait {
    margin-top:1rem;
    width:100%;
    img {
      width:100%;
    }
  }

}

/* Tablet */
@media only screen
and (min-device-width: 668px)
and (max-device-width: 1024px)
{
  article.architekt {
    max-width:80%;
  }
  .felixkuhn {
    margin-top:8rem;
    .article {
      max-width:100%;
    }
    .adresse, .cv {
      width:60%;
    }
    .adresse {
      float:left;
    }
    .portrait {
      width:30%;
      float:right;
      img {
        width:100%;
      }
    }
  }
}

/* Desktop */
@media only screen
and (min-device-width: 1025px)
{
  html {
    overflow-y: scroll;
  }
  h1 {
    font-size:1.5rem;
  }
  h2 {

  }
  header {
    margin-top:40px;
  }
  nav {

  }

  article {
    //max-width:600px;
  }
  article.architekt {
    max-width:60%;
  }
  .felixkuhn {
    margin-top:8rem;
    .article {
      max-width:100%;
    }
    .adresse, .cv {
      width:60%;
    }
    .adresse {
      float:left;
    }
    .portrait {
      width:30%;
      float:right;
      img {
        width:100%;
      }
    }
  }
}

.slick-current {
  //border-bottom:2px solid $hellgrau;
}






