$height-mobile: 130px;
$height-tablet: 200px;
$height-desktop: 250px;


nav {
  height: $height-mobile;
}
.prev, .next {
  //visibility: hidden;
  opacity: 0.3;
  filter: alpha(opacity=30);
  float:left;
  width:30px;
  height:100px;
  padding-top:40px;
}
.prev {
  padding-right:10px;
}
.next {
  padding-left:10px;
}
.slick-slider {
  float:left;
  width:calc(100% - 60px);
  visibility: hidden;
}

.slick-slider img {
  height: $height-mobile;
}


.slick-slide img {
  padding-right:40px;
}

.slick-current img {
  //border-bottom: 2px solid #8a8a8a;
}

// Workaround um Slider erst anzuzeigen wenn geladen (siehe aus visibility by .slick-slider
.slick-initialized {
  visibility: visible;
}

/* Tablet */
@media only screen
and (min-device-width: 668px)
and (max-device-width: 1024px)
{
  nav {
    height: $height-tablet;
  }
  .slick-slider img {
    height: $height-tablet;
  }
  .prev, .next {
    height:200px;
    padding-top:80px;
  }
  .slick-slide img {
    padding-right:60px;
  }
}

/* Desktop */
@media only screen
and (min-device-width: 1025px)
{
  nav {
    height: $height-desktop;
  }
  .slick-slider img {
    height: $height-desktop;
  }
  .prev, .next {
    height:250px;
    padding-top:105px;
  }
  .slick-slide img {
    padding-right:60px;
  }
}